import HystModal from "./hystmodallib";
import "element-closest-polyfill";
import refsCommon from "../common/refsCommon";
import { setCookie, getCookie, deleteCookie } from "../common/cookie";

const { acceptBtnCookie, deleteBtnCookie } = refsCommon;

const onClickAccept = () => {
	// cookie accept logic
	setCookie("cookie_Diligeme", "accepted", 1); // зберігаємо кукі 1 день

	myModal.close("#modalCookie");
};
const onClickDelete = () => {
	// cookie accept logic
	deleteCookie("cookie_Diligeme"); //

	myModal.close("#modalCookie");
};

global.HystModal = HystModal;

const myModal = new HystModal({
	// for dynamic init() of modals
	// linkAttributeName: false,
	catchFocus: false,
	closeOnEsc: true,
	backscroll: false,
	closeOnOverlay: false,
	closeOnEsc: false,
	beforeOpen: function (modal) {
		// console.log('Message before opening the modal');
		// console.log(modal); //modal window object
	},
	afterClose: function (modal) {
		// console.log('Message after modal has closed');
		// console.log(modal); //modal window object

		//If Youtube video inside Modal, close it on modal closing
		let videoframe = modal.openedWindow.querySelector("iframe");
		if (videoframe) {
			videoframe.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', "*");
		}
	},
});

acceptBtnCookie.addEventListener("click", onClickAccept);
deleteBtnCookie.addEventListener("click", onClickDelete);

window.onload = function () {
	if (getCookie("cookie_Diligeme") !== "accepted") {
		myModal.open("#modalCookie");
	}
};
